import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "@stylesPages/Contact.scss";

import Header from "@components/Header";
import Footer from "@components/Footer";
import Hero from "@components/Hero";
import ScrollTabs from "@components/ScrollTabs";
import FormContact from "@components/FormContact";

export default function Contact() {
  const { t } = useTranslation("support");
  return (
    <>
      <Header />
      <main className="contact">
        <Hero title={t("title")} />
        <section className="contact__section">
          <h2 className="contact__title">{t("subtitle-email")}</h2>
          <div className="contact__section-flex">
            <article className="contact__email">
              <a
                href="mailto:support@fxbravo.net"
                className="contact__email-title"
              >
                support@fxbravo.net
              </a>
              <p className="contact__email-text">{t("text-support-email")}</p>
            </article>
            <article className="contact__email">
              <a
                href="mailto:finance@fxbravo.net"
                className="contact__email-title"
              >
                finance@fxbravo.net
              </a>
              <p className="contact__email-text">{t("text-finance-email")}</p>
            </article>
            <article className="contact__email">
              <a
                href="mailto:compliance@fxbravo.net"
                className="contact__email-title"
              >
                compliance@fxbravo.net
              </a>
              <p className="contact__email-text">
                {t("text-compliance-email")}
              </p>
            </article>
          </div>
        </section>
        <section className="contact__section contact__section--big">
          <h2 className="contact__title">{t("subtitle-tel")}</h2>
          <div className="contact__section-flex">
            <article className="contact__tel">
              <a href="tel:+548006663602" className="contact__tel-title">
                +548006663602
              </a>
              <p className="contact__tel-text">{t("text-ar-tel")}</p>
            </article>
            <article className="contact__tel">
              <a href="tel:+56800681009" className="contact__tel-title">
                +56800681009
              </a>
              <p className="contact__tel-text">{t("text-cl-tel")}</p>
            </article>
            <article className="contact__tel">
              <a href="tel:+528005220062" className="contact__tel-title">
                +528005220062
              </a>
              <p className="contact__tel-text">{t("text-mx-tel")}</p>
            </article>
            <article className="contact__tel">
              <a href="tel:+5180074566" className="contact__tel-title">
                +5180074566
              </a>
              <p className="contact__tel-text">{t("text-pe-tel")}</p>
            </article>
          </div>
        </section>
        <FormContact title={t("title-form")} />
        <ScrollTabs
          tabs={[
            {
              id: 0,
              title: "verification",
              content: "verification-content"
            },
            {
              id: 1,
              title: "security",
              content: "security-content"
            },
            {
              id: 2,
              title: "platform",
              content: "platform-content"
            },
            {
              id: 3,
              title: "account",
              content: "account-content"
            }
          ]}
          translation="support"
          titleBig="faq"
          htmlOn
        />
      </main>
      <Footer />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
