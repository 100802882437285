import { useI18next } from "gatsby-plugin-react-i18next";
import swal from "sweetalert";

import "@stylesComponents/FormContact.scss";

export default function FormContact({ title }) {
  const { t } = useI18next("support");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const form = new FormData(event.target);
      const dataToSend = {
        firstName: form.get("name"),
        lastName: form.get("lastname"),
        country: form.get("country"),
        phoneNumber: form.get("phone"),
        email: form.get("email"),
        subject: form.get("subject"),
        message: form.get("message")
      };

      const request = await fetch("https://fxbravo.net/api/sendEmail.php", {
        method: "POST",
        body: JSON.stringify(dataToSend)
      });
      const response = await request.json();
      if (request.status !== 200) {
        throw new Error(JSON.stringify(response));
      }

      event.target.reset();
      swal("Enviado!", response.data, "success");
    } catch (error) {
      const err = JSON.parse(error.message);
      swal("Ups!", err.data, "error");
    }
  };

  return (
    <form className="form-contact" onSubmit={handleSubmit}>
      <h2 className="form-contact__title">{title}</h2>
      <div className="form-contact__grid">
        <label htmlFor="name" className="form-contact__label">
          <p className="form-contact__label-text">{t("name")}</p>
          <input id="name" name="name" type="text" className="form-contact__label-input" required />
        </label>
        <label htmlFor="lastname" className="form-contact__label">
          <p className="form-contact__label-text">{t("lastname")}</p>
          <input id="lastname" name="lastname" type="text" className="form-contact__label-input" required />
        </label>
        <label htmlFor="country" className="form-contact__label">
          <p className="form-contact__label-text">{t("country")}</p>
          <input id="country" name="country" type="text" className="form-contact__label-input" />
        </label>
        <label htmlFor="phone" className="form-contact__label">
          <p className="form-contact__label-text">{t("phone")}</p>
          <input id="phone" name="phone" type="tel" className="form-contact__label-input" required />
        </label>
        <label htmlFor="email" className="form-contact__label">
          <p className="form-contact__label-text">{t("email")}</p>
          <input id="email" name="email" type="text" className="form-contact__label-input" required />
        </label>
        <label htmlFor="subject" className="form-contact__label">
          <p className="form-contact__label-text">{t("subject")}</p>
          <input id="subject" name="subject" type="text" className="form-contact__label-input" />
        </label>
        <label htmlFor="message" className="form-contact__label form-contact__label--message">
          <p className="form-contact__label-text">{t("message")}</p>
          <textarea
            id="message"
            name="message"
            type="text"
            className="form-contact__label-input form-contact__label-input--message"
            rows="4"
          />
        </label>
      </div>
      <button type="submit" className="form-contact__submit">{t("send")}</button>
    </form>
  );
}
