import { useRef, useState, useEffect } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";

import "@stylesComponents/ScrollTabs.scss";

export default function ScrollTabs({ tabs = [], translation = "", ...props }) {
  const [tabActive, setTabActive] = useState({
    title: tabs[0].title || "",
    content: tabs[0].content || ""
  });
  const tabContentRef = useRef(null);
  const tabRef = useRef(null);
  const { t } = useI18next(translation);

  const handleTab = (tab) => {
    setTabActive({
      ...tabActive,
      title: tab.title,
      content: tab.content
    });
    tabContentRef.current.scrollTo(0, 0);
    if (matchMedia("(max-width: 535px)").matches) {
      tabRef.current.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const right = params.has("1");
    if (right) {
      handleTab({
        title: "inactivity",
        content: "inactivity-content"
      });
    }
  }, []);

  return (
    <div className="scrolltabs">
      {!!props.titleBig && (
        <h1 className="scrolltabs__title">{t(props.titleBig)}</h1>
      )}
      <div className="scrolltabs__content">
        <aside className="scrolltabs__tabs">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => handleTab(tab)}
              type="button"
              className="scrolltabs__tabs-action"
              data-active={tab.title === tabActive.title}
            >
              {t(tab.title)}
            </button>
          ))}
        </aside>
        <section className="scrolltabs__tab" ref={tabRef}>
          {!props.titleBig && (
            <h1 className="scrolltabs__tab-title">{t(tabActive.title)}</h1>
          )}
          {props.htmlOn ? (
            <div
              className="scrolltabs__tab-text"
              dangerouslySetInnerHTML={{ __html: t(tabActive.content) }}
              ref={tabContentRef}
            />
          ) : (
            <p className="scrolltabs__tab-text" ref={tabContentRef}>
              {t(tabActive.content)}
            </p>
          )}
        </section>
      </div>
    </div>
  );
}
